<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>记录查询</el-breadcrumb-item>
      <el-breadcrumb-item>停车记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pk_id"
            filterable
            placeholder="请选择"
            @change="pkIdChange()"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >车牌号码：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入车牌号码"
            v-model.trim="query.plateNo"
            clearable
        /></label>
        <label style="margin: 10px" for=""
          >入场通道：
          <el-select
            v-model="query.doorId"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in pkDoorList"
              :key="item.id"
              :label="item.doorName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <el-radio-group v-model="radio" @change="radioChange">
          <el-radio label="1">按进出时间查询</el-radio>
          <el-radio label="2">按离场时间查询</el-radio>
        </el-radio-group>
        <label style="margin: 10px" for=""
          >进场时间：<el-date-picker
            v-model="inTimeBegin"
            :disabled="radio == 2"
            @change="inTimeBeginchange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="inTimeEnd"
            :disabled="radio == 2"
            @change="inTimeEndchange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
          >
          </el-date-picker
        ></label>
        <label style="margin: 10px" for=""
          >离场时间：<el-date-picker
            v-model="outTimeBegin"
            :disabled="radio == 1"
            @change="outTimeBeginchange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="outTimeEnd"
            :disabled="radio == 1"
            @change="outTimeEndchange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
          >
          </el-date-picker
        ></label>
        <div v-show="showHandover == true">
          <label style="margin: 10px" for=""
            >车辆类型：
            <el-select
              v-model="query.plateType"
              clearable
              placeholder="请选择"
              style="width: 200px"
              size="small"
            >
              <el-option
                v-for="item in typeCar"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </label>
          <label style="margin: 5px" for="">
            年份:
            <el-date-picker
              size="small"
              v-model="query.t_year"
              type="year"
              placeholder="选择年份"
              format="yyyy"
              value-format="yyyy"
              style="width: 185px"
              :picker-options="pickerOptions"
              @change="tYearchange"
            />
          </label>
        </div>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="pkInoutDatacx"
          >查询</el-button
        >
        <el-button size="small" @click="pkInoutDatacz">重置</el-button>
        <div class="deployment">
          <div v-show="showHandover == false" @click="showHandover = true">
            展开<i class="el-icon-arrow-down"></i>
          </div>
          <div v-show="showHandover == true" @click="showHandover = false">
            收起<i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button type="primary" size="small" @click="pkInoutDataexcel"
        >导出</el-button
      >
      <el-button
        type="danger"
        size="small "
        icon="el-icon-delete"
        @click="batchdel"
        >删除选中记录</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          ref="multipleTable"
          :data="tableData"
          stripe
          @sort-change="sortchange"
          @selection-change="handleSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="trueName"
            label="姓名"
            width="80"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="plateNo"
            label="车牌号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="fee"
            label="停车费"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inTime"
            label="进场时间"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.inTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="outTime"
            label="离场时间"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.outTime ? conversionTime(scope.row.outTime) : ''
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="pkTime"
            label="停车时长"
            width="200"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.inTime && scope.row.outTime
                  ? timeFn(scope.row.inTime, scope.row.outTime)
                  : ''
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inPicPath"
            label="进场照片"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.inPicPath"
                :preview-src-list="inimgList"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="outPicPath"
            label="离场照片"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.outPicPath"
                :preview-src-list="outimgList"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="150"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="jinchuClick(scope.row.id)"
                size="mini"
                icon="el-icon-share"
                >进出记录</el-button
              >
              <el-button
                type="text"
                @click="delClick(scope.row.id)"
                size="mini"
                icon="el-icon-delete"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {
  pkInoutData,
  pkDoor,
  delpkInoutData,
  pkInoutDataexcel,
} from '@/api/record.js'
import { sysDictData } from '@/api/dictionaries.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
export default {
  data() {
    return {
      showHandover: true,
      isShowData: false,
      tableData: [],
      inTimeBegin: undefined, // 进场开始时间
      inTimeEnd: undefined, // 进场结束时间
      outTimeBegin: undefined, // 出场开始时间
      outTimeEnd: undefined, // 出场结束时间
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pk_id: undefined,
        t_year: undefined, // 年份
        plateNo: undefined, // 车牌号
        inTimeBegin: undefined, // 进场开始时间
        inTimeEnd: undefined, // 进场结束时间
        outTimeBegin: undefined, // 出场开始时间
        outTimeEnd: undefined, // 出场结束时间
        doorId: undefined, // 通道id
        plateType: undefined, // 车牌类型
        sortField: 'in_time', // 进场时间 in_time 离场时间 out_time
        sortType: 'desc', // 排序类型 默认desc  asc升序 desc降序
      },
      total: undefined,
      pkDoorList: [], // 保存通道列表
      typeCar: [], // 保存车类型列表
      PkInfoSelect: [], // 保存停车场下拉框
      batchid: '', // 保存批量选中的id
      radio: '1',
      inimgList: [],
      outimgList: [],
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  computed: {
    // 控制年份日期范围
    pickerOptions() {
      const currentDate = new Date()
      const currentYear = new Date().getFullYear()
      // 获取当前月份和日期
      let currentMonth = currentDate.getMonth() + 1
      let currentDay = currentDate.getDate()
      // 检查是否大于等于6月1日
      let isAfterJune =
        currentMonth > 6 || (currentMonth === 6 && currentDay >= 1)
      return {
        disabledDate(time) {
          return isAfterJune
            ? time.getFullYear() < 2020 || time.getFullYear() > currentYear
            : time.getFullYear() < 2020 ||
                time.getFullYear() == currentYear - 1 ||
                time.getFullYear() > currentYear
        },
      }
    },
  },
  methods: {
    //年份可选
    tYearchange(value) {
      if (value == null) {
        this.query.t_year = undefined
      } else {
        this.query.t_year = value
      }
    },
    radioChange(val) {
      if (val == 1) {
        this.outTimeBegin = undefined
        this.outTimeEnd = undefined
        this.query.sortField = 'in_time'
      } else if (val == 2) {
        this.inTimeBegin = undefined
        this.inTimeEnd = undefined
        this.query.sortField = 'out_time'
      }
    },
    inTimeBeginchange() {
      this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 7 - 1000
    },
    inTimeEndchange() {
      if (this.inTimeBegin === null || this.inTimeBegin === undefined) {
        this.inTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.inTimeEnd < this.inTimeBegin) {
        this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      } else if (this.inTimeEnd - this.inTimeBegin > 3600 * 1000 * 24 * 7) {
        this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 7 - 1000
        return this.$message.warning('不可选择比开始时间超过七天以外的日期')
      }
    },
    outTimeBeginchange() {
      this.outTimeEnd = this.outTimeBegin + 3600 * 1000 * 24 * 7 - 1000
    },
    outTimeEndchange() {
      if (this.outTimeBegin === null || this.outTimeBegin === undefined) {
        this.outTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.outTimeEnd < this.outTimeBegin) {
        this.outTimeEnd = this.outTimeBegin + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      } else if (this.outTimeEnd - this.outTimeBegin > 3600 * 1000 * 24 * 7) {
        this.outTimeEnd = this.outTimeBegin + 3600 * 1000 * 24 * 7 - 1000
        return this.$message.warning('不可选择比开始时间超过七天以外的日期')
      }
    },
    pkInoutDataexcel() {
      if (this.radio === '1') {
        if (this.inTimeBegin - this.inTimeEnd > 3600 * 1000 * 24 * 7) {
          return this.$message.warning('只能导出一个礼拜以内的数据')
        }
        this.query.inTimeEnd = this.inTimeEnd
        this.query.inTimeBegin = this.inTimeBegin
        if (this.query.inTimeEnd || this.query.inTimeBegin) {
          this.query.inTimeEnd = (this.query.inTimeEnd / 1000).toFixed(0)
          this.query.inTimeBegin = (this.query.inTimeBegin / 1000).toFixed(0)
        }
        if (!this.query.inTimeEnd || !this.query.inTimeBegin) {
          return this.$message.warning('请选择导出的具体进场时间')
        }
      } else {
        if (this.outTimeBegin - this.outTimeEnd > 3600 * 1000 * 24 * 7) {
          return this.$message.warning('只能导出一个礼拜以内的数据')
        }
        this.query.outTimeEnd = this.outTimeEnd
        this.query.outTimeBegin = this.outTimeBegin
        if (this.query.outTimeEnd || this.query.outTimeBegin) {
          this.query.outTimeEnd = (this.query.outTimeEnd / 1000).toFixed(0)
          this.query.outTimeBegin = (this.query.outTimeBegin / 1000).toFixed(0)
        }
        if (!this.query.outTimeEnd || !this.query.outTimeBegin) {
          return this.$message.warning('请选择导出的具体离场时间')
        }
      }
      pkInoutDataexcel(this.query, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          window.open(res.data.data)
        }
      })
    },
    sortchange(column, prop, order) {
      if (column.prop == 'inTime') {
        this.query.sortField = 'in_time'
      } else if (column.prop == 'outTime') {
        this.query.sortField = 'out_time'
      }
      if (column.order == 'descending') {
        this.query.sortType = 'desc'
      } else if (column.order == 'ascending') {
        this.query.sortType = 'asc'
      }
      this.pkInoutData()
    },
    // 计算两个时间差 dateBegin 开始时间
    timeFn(dateBegin, dateEnd) {
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      var dateDiff = dateEnd * 1000 - dateBegin * 1000 //时间差的毫秒数时间戳
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000)
      var leave4 = leave3 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var minseconds = Math.round(leave4 / 1000)
      // var timeFn = "耗时："+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒"+minseconds+"毫秒";
      var timeFn =
        dayDiff + '天 ' + hours + '小时 ' + minutes + ' 分钟' + seconds + ' 秒'
      return timeFn
    },
    pkInoutData() {
      console.log(new Date().getFullYear())
      const currentYear = new Date().getFullYear()
      if (this.query.t_year == currentYear) {
        this.query.t_year = undefined
      }
      // 进出记录查询列表
      this.query.inTimeBegin = this.inTimeBegin
      this.query.inTimeEnd = this.inTimeEnd
      this.query.outTimeBegin = this.outTimeBegin
      this.query.outTimeEnd = this.outTimeEnd
      if (this.query.inTimeBegin && !this.query.inTimeEnd) {
        return this.$message.warning('请输入进场截止时间')
      } else if (!this.query.inTimeBegin && this.query.inTimeEnd) {
        return this.$message.warning('请输入进场开始时间')
      }
      if (this.query.outTimeBegin && !this.query.outTimeEnd) {
        return this.$message.warning('请输入离场截止时间')
      } else if (!this.query.outTimeBegin && this.query.outTimeEnd) {
        return this.$message.warning('请输入离场开始时间')
      }
      if (this.query.inTimeBegin || this.query.inTimeEnd) {
        this.query.inTimeBegin = (this.query.inTimeBegin / 1000).toFixed(0)
        this.query.inTimeEnd = (this.query.inTimeEnd / 1000).toFixed(0)
      }
      if (this.query.outTimeBegin || this.query.outTimeEnd) {
        this.query.outTimeBegin = (this.query.outTimeBegin / 1000).toFixed(0)
        this.query.outTimeEnd = (this.query.outTimeEnd / 1000).toFixed(0)
      }
      pkInoutData(this.query, { pk_id: this.query.pk_id }).then((res) => {
        this.total = res.data.data.total
        this.tableData = res.data.data.list
        this.inimgList = res.data.data.list.map((item) => item.inPicPath)
        this.outimgList = res.data.data.list.map((item) => item.outPicPath)

        if (this.tableData) {
          this.isShowData = true
        }
      })
    },
    pkIdChange() {
      // 停车场变化后获取入场通道
      this.pkDoor()
    },
    pkInoutDatacx() {
      // 查询
      this.query.current = 1
      this.pkInoutData()
    },
    pkInoutDatacz() {
      // 重置
      this.query.pk_id = this.PkInfoSelect[0].id
      this.query.plateNo = undefined
      this.inTimeBegin = undefined
      this.inTimeEnd = undefined
      this.outTimeBegin = undefined
      this.outTimeEnd = undefined
      this.query.doorId = undefined
      this.query.plateType = undefined
      this.pkInoutData()
    },
    pkDoor() {
      // 获取通道下拉
      pkDoor({ pk_id: this.query.pk_id }).then((res) => {
        this.pkDoorList = res.data.data
      })
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pk_id = this.PkInfoSelect[0].id
          this.pkInoutData()
          this.sysDictData()
          this.pkDoor()
        }
      })
    },
    sysDictData() {
      // 字典
      sysDictData({ typeId: 40 }).then((res) => {
        // 车类型
        this.typeCar = res.data.data
      })
    },
    delClick(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delpkInoutData(id, { pk_id: this.query.pk_id }).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('删除成功')
              this.pkInoutData()
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    handleSelectionChange(val) {
      // 点击table选中
      this.batchid = ''
      val.forEach((item) => {
        this.batchid += item.id + '_'
      })
      this.batchid = this.batchid.slice(0, this.batchid.length - 1)
    },
    batchdel() {
      // 批量删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delpkInoutData(this.batchid, { pk_id: this.query.pk_id }).then(
            (res) => {
              if (res.data.code === '200') {
                this.$message.success('删除成功')
                this.pkInoutData()
              }
            }
          )
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    jinchuClick(id) {
      this.$router.push({
        path: 'Inandout',
        query: { id: id, pkid: this.query.pk_id },
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.query.size = val
      this.pkInoutData()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.query.current = val
      this.pkInoutData()
    },
  },
}
</script>
<style lang="scss" scoped>
.deployment {
  color: #1677ff;
  cursor: pointer;
  margin-left: 10px;
}
::v-deep .el-image-viewer__close {
  color: #333;
}
::v-deep .el-image-viewer__mask {
  position: fixed;
  opacity: 0;
  background-color: #333;
}
::v-deep .el-image-viewer__canvas {
  height: 80%;
  padding-top: 100px;
  box-sizing: border-box;
}
::v-deep .el-image-viewer__wrapper {
  top: 50px;
  left: 20%;
  right: 20%;
  bottom: 50px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 20px 1px #333;
}
</style>
